/** SASS VARIABLES **/
$baby-blue: #65AAC7;
$blue: #006A91;
$black: #333333;
$grey: #77787B;
$opensans: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;
$fontawesome: "Font Awesome 5 Pro";

html {
}

body {
	background: #FFFFFF;
	font-size: 18px;
	color: $black;
	overflow-x: hidden;

	* {
		font-family: $opensans;
	}
}

body.slideout-open {

	#panel {
		-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
		-moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	}
}

main {
	border: 0px;
}

.clear {
	clear: both;
}

.container {
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
}

.container-fluid {
	padding-right: 25px;
	padding-left: 25px;
}

.slideout-panel {
	margin-bottom: 0px;
}

h2 {
	font-size: 35px;
	font-weight: 600;
	color: $blue;
	margin-bottom: 20px;
}

h2.black-title {
	color: $black;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
}

h3 {
	font-size: 23px;
	color: $baby-blue;
	margin-bottom: 15px;
	margin-top: 30px;
}

p {
	color: $black;
	font-size: 15px;
	line-height: 25px;
	margin-bottom: 25px;
}

ul,ol {
	margin-bottom: 30px;
}

p a,
a,
a:visited {
	color: $blue;
	text-decoration: none !important;
}

a:hover {
	text-decoration: none;
	color: $baby-blue;
}

a.rounded-button {
	border-radius: 28px;
	background: #006A91;
	text-transform: uppercase;
	padding: 15px 30px;
	color: #FFFFFF;
	font-size: 17px;
}

a.rounded-button.learn-more {
	border-radius: 28px;
	border: 1px solid $blue;
	font-size: 17px;
	padding: 8px 35px;
	color: #006A91;
	transition: .3s;
	background: transparent;
	text-transform: none;
}

a.rounded-button.learn-more:hover {
	background: $blue;
	color: #FFFFFF;
	transition: .3s;
}

.normal.CollapseLink, 
.normal.ExpandLink {
	display: none;
}

@media screen and (max-width: 991px) {
	html {
		overflow-x: hidden;
	}
}

@media screen and (min-width: 992px) {

	h2 {
		font-size: 35px;
		font-weight: 600;
		color: $blue;
	}

	h3 {
		font-size: 23px;
		font-weight: 600;
		color: $baby-blue;
	}

	p {
		font-size: 16px;
	}

	.container,
	.container-fluid {
	    padding-right: 15px;
	    padding-left: 15px;
	}

	.hide-desktop {
		display: none !important;
	}
}

/* DNN FIXES */
.dnnModuleManager.dnnSortable {
}

/* mobile styles */
header.sub-header {
	background: $baby-blue;
	padding: 8px 0px;
	display: none;

	.col-xs-8 {
		padding-left: 0px;
	}

	.col-xs-4 {
		padding-right: 0px;
	}

	.links-left {
		padding-top: 4px;
		ul {
			margin-left: 0px;
			margin-bottom: 0px;
			li {
				list-style-type: none;
				display: inline-block;
				margin-right: 25px;
				a {
					color: #FFFFFF;
					font-size: 14px;
					text-decoration: none !important;
				}

				a:hover {
					text-decoration: none;
					opacity: .8;
				}

				i {
					margin-right: 4px;
				}

				img {
					margin-right: 3px;
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-top: -3px;
				}

				span {
					display: inline-block;
				}
			}
		} /* ul */
	} /* links-left */

	ul.searchSkinObjectPreview {
		display: none;
	}

	.search-container {
		float: right;
		position: relative;

		.dnnSearchBoxClearText {
			display: none;
		}

		#dnn_ucHeader_dnnSearch_txtSearch {
			background: #B2D4E3;
			width: 335px;
    		border-radius: 20px !important;
    		border: 0px;
    		height: 38px;
		}

		#dnn_ucHeader_dnnSearch_cmdSearch {
			
		}

		.show {
			display: block !important;
		}

		i {
			position: absolute;
			top: 10px;
			right: 15px;
			font-size: 16px;
		}
	}
} /* header.sub-header */


header.main-header {
	border-bottom: 0px;
	-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	margin-bottom: 10px;

	#mainHeader-inner {
		padding: 18px 0px;
	}

	.navbar {
		min-height: 0;
		margin-bottom: 0px;
		border: 0px;
	}

	.navbar-header {
		float: right;
		padding-right: 15px;
		padding-top: 6px;
		i {
			color: $baby-blue;
			font-size: 26px;
		}

	} /* navbar-header */

	#navbar {
		display: none !important;
	} /* navbar */

	#logo {
		img {
			width: 165px;
		}
	} /* logo */

} /* header */

/* mobile menu */

.slideout-open .mobile-menu {
	opacity: 1;
}

.mobile-menu {
	opacity: 0;

	.nav {
		margin: 0px;

		li {
			display: block;
			width: 100%;
			position: relative;
			background: $baby-blue;
			border-bottom: 1px solid #FFF;

			a {
				background: none !important;
				color: #FFFFFF;
				padding: 20px 15px;
			}

			a:hover {
			}

			.caret {
				transition: .5s;
				border-right: 0px;
				border-left: 0px;
				margin-left: 5px;
				z-index: 9999;
				position: absolute;
				right: 50px;
				top: 16px;
			}

			.caret::after {
				content: '\f067';
				font-family: $fontawesome;
				font-size: 20px;
				padding: 20px;
			}

			.dropdown-menu {
				position: initial;
				border: 0px;
				box-shadow: none;
				float: none;
				min-width: 0px;
				border-radius: 0px;
				margin: 0px;
				padding: 0px;

				li {
					background: $blue;
					a {
						white-space: normal !important;
						font-size: 15px;
						margin-left: 15px;
					}

					a:before {
					}
				}

				li:last-of-type {
					border-bottom: 0px;
				}
			}
		} /* li */

		li.active {
			background: #FFFFFF !important;

			> a {
				color: $baby-blue;
			}
		}

		li:hover {

			a {

			}
		}

		li.dropdown.expanded {

			.caret {
				transition: .5s;
			}

			.caret::after {
				content: '\f068';
			}

			.dropdown-menu {
				display: block;
			}

		}
	} /* nav */
} /* mobile-menu */

@media screen and (min-width: 992px) {
	header.sub-header {
		display: block;
	}	

	header.main-header {
		margin-bottom: 0px;
		box-shadow: none;

		#navbar {
			display: block !important;
			margin-top: -25px;

			ul.nav {
				li {
					height: 30px;

					a {
						padding-top: 0px;
						padding-bottom: 0px;
						color: $grey;
						text-transform: uppercase;
						font-size: 13px;
						font-weight: 600;
						background: none !important;
					}

					a:hover {
						background: none;
						color: $baby-blue;
					}

					ul.dropdown-menu {
						border-top: 3px solid $baby-blue;
						min-width: 240px;
						margin-left: 13px;
						padding: 10px 0px;
						border-radius: 0px;
						box-shadow: 0 0px 4px rgba(0,0,0,.175);

						li {
							margin-bottom: 8px;
							height: initial;

							a {
								word-wrap: normal;
								background: none !important;
							}
						}

						li:last-of-type {
							margin-bottom: 0px;
						}
					} /* ul.dropdown-menu */

				} /* li */

				li.dropdown:hover {
					>.dropdown-menu  {
						display: block;
					}

				}
			} /* ul.nav */

		} /* navbar */

		.navbar-header {
			display: none;
		}

	}
} /* min-width 992px */

@media screen and (min-width: 1200px) {

	header.main-header {
		#mainHeader-inner {
			padding: 23px 0px;
		}

		#logo {
			img {
				width: 235px;
			}
		} /* logo */

		#navbar {
			display: block !important;
			margin-top: -36px;

			ul.nav {
				li {
					height: 32px;

					a {
						padding-top: 0px;
						padding-bottom: 0px;
						color: $grey;
						text-transform: uppercase;
						font-size: 15px;
						font-weight: 600;
					}

					.dropdown-menu {

						li {
							a {
								padding: 2px 18px;
							}
						}
					}
				} /* li */

			} /* ul.nav */

		} /* navbar */
	}

} /* min-width 1200px */

/* Home Page */

.home-page {

	header {
		margin-bottom: 0px;
	}

	hr {
		border: 2px solid $blue;
		max-width: 95px;
		margin: 0 auto;
		margin-top: 35px;
		margin-bottom: 35px;
	}

	.home-page-hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC homecare_mobile_homepage_hero.png');
		background-size: cover;
		background-position: center;

		.content-container {
			padding: 60px 0px;
			text-align: center;
			height: 100%;

			h2 {
				color: $black;
				font-size: 40px;
				font-weight: normal;
				line-height: 54px;
				max-width: 285px;
				margin: 0 auto;
			}

			p {
				color: $black;
				font-size: 20px;
				line-height: 35px;
				max-width: 285px;
				margin: 0 auto;
				margin-bottom: 55px;
			}

			a.rounded-button {
				background: $blue;
				text-transform: uppercase;
				padding: 15px 30px;
				color: #FFFFFF;
				font-size: 17px;
				border: 1px solid $blue;
			}

			a.rounded-button:hover {
				background: transparent;
				color: $blue;
			}
		}
	} /* home-page-hero */

	.patient-care {

		.image-left {
			background: url(/Portals/_default/Skins/integriTheme/images/bjc-home-care-patient-care.png);
			background-size: cover;
			background-position: center;
			height: 100%;
			height: 1077px;
		}

		.content {
			padding: 50px 0px;
			background-color: #f9fbfc;
			background-image: url(/Portals/_default/Skins/integriTheme/Images/bjc-home-care-inner-page-content-top-swoosh.png);
		    background-repeat: no-repeat;
		    background-position-y: 0px;
		    background-position-x: 10px;
		    background-size: 100%;
		    text-align: center;
			
			h2 {
				text-align: center;
				margin-bottom: 0px;
			}

			hr {
				margin-top: 22px;
    			margin-bottom: 25px;			
    		}

    		img {
    			max-width: 350px;
    			margin: 0 auto;
    		}

    		.container-fluid {

    		}
		} /* content */

		.patient-care-services {	
			text-align: left;
			max-width: 400px;
			margin: 0 auto;		

			ul {
				margin-left: 0px;
				margin-bottom: 0px;
				padding-left: 0px;
				padding-bottom: 0px;
				border: 1px solid $baby-blue;
				border-radius: 22px;
				overflow: hidden;
				margin-top: 40px;

				li.header-row {
					background: #ddecf3;
					color: $black;
					padding: 19px 20px;
					position: relative;

					h3 {
						margin-top: 0px;
						margin-bottom: 0px;
						color: $black;
						font-size: 18px;
						display: inline-block;
						font-weight: 600;
					}

					i {
						display: inline-block;
						color: $blue;
						position: absolute;
						top: 24px;
						right: 20px;
					}

				}

				li {

				}

				li.service {
					border-bottom: 1px solid $baby-blue;
					padding: 15px 20px;
					position: relative;
					height: 68px;

					.full-width-link {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0px;
						left: 0px;
					}

					.link-text {
						position: absolute;
						left: 75px;
						top: 22px;
					}
				}

				li.service:last-of-type {
					border-bottom: 0px;
				}

				.service.cancer {

					img {
						width: 23px;
						height: 33px;	
					}
				}

				.service.cardiac {
					img {
						width: 24px;
						height: 32px;
					}
				}

				.service.copd {
					img {
						width: 30px;
						height: 33px;
					}
				}

				.service.dementia {
					img {
						width: 25px;
						height: 32px;
					}
				}

				.service.diabetes {
					img {
						width: 20px;
						height: 37px;
						margin-left: 2px;
					}
				}

				.service.nutritional-support {
					img {
						width: 30px;
						height: 36px;
						margin-left: -3px;
					}
				}

				.service.post-surgery {
					img {
						width: 30px;
						height: 21px;
						margin-top: 8px;
					}
				}

				.service.strength-and-conditioning {
					img {
						width: 30px;
						height: 34px;
					}
				}

				.service.stroke {
					img {
						width: 30px;
						height: 28px;
						margin-top: 5px;
						margin-left: -1px;
					}
				}

				.service.wound-care {
					img {
						width: 27px;
						height: 27px;
						margin-top: 6px;
					}
				}
			} /* ul */

		} /* patient-care-services */
	} /* patient-care */

	.ctas-container {
		background: #f1f5f7;
		padding: 100px 0px;

		.cta {
			text-align: center;
			max-width: 320px;
			margin: 0 auto;
			margin-top: 40px;

			img {
				max-width: 140px;
				height: auto;
			}

			h3 {
				font-weight: 600;
			}

			p {
				font-size: 16px;
			}

			.learn-more {
				margin-top: 30px;
			}
		}
	} /* ctas-container */

	.what-we-do {

		.content {
			text-align: center;
			padding: 50px 0px;
			margin-left: auto;
			background-image: url(/Portals/_default/Skins/integriTheme/Images/bjc-home-care-inner-page-content-top-swoosh.png);
		    background-repeat: no-repeat;
		    background-position-y: 0px;
		    background-position-x: 10px;
		    background-size: 100%;

			.black-title {
				margin-bottom: 0px;
			}

			h3 {
				font-weight: 600;
			}

			hr {
				margin-top: 22px;
				margin-bottom: 25px;
			}

			p {
				margin-bottom: 40px;
			}

			img {
				margin-top: 40px;
			}

			.video-container {
				position: relative;
				display: flex !important;
				justify-content: center;
				align-items: center;
				background-image: url('/Portals/_default/Skins/integriTheme/Images/bjc-home-care-what-we-do.png');
				min-height: 465px;
				background-size: cover;
				background-position: center;
				margin-top: 50px;

				.play-button {
					background: rgba(255, 255, 255, .5);
					border-radius: 45px;
					padding: 28px 5px 20px 15px;
					width: 90px;
					height: 90px;
					display: block;

					i {
						font-size: 35px;
					}
				}
			}
		}
	} /* what-we-do */

	.testimonial {
		background: #f1f5f7;
		padding: 50px 0px;
		text-align: center;

		i {
			color: $blue;
			margin-bottom: 40px;
			font-size: 35px;
		}

		h2.black-title {
			margin-bottom: 0px;
		}

		hr {
			margin-top: 25px;
			margin-bottom: 50px;
		}

		p {
			color: $grey;
			font-size: 28px;
			text-align: left;
			font-weight: 600;
			line-height: 40px;
			max-width: 320px;
			margin: 0 auto;
		}
	}

	footer.questions {
		margin-top: 0px;
	}

} /* home-page */

/* home page desktop */

@media screen and (min-width: 600px) {

	.home-page {

		.home-page-hero {

			.content-container {

				h2 {
					max-width: 600px;
				}

				p {
					max-width: 600px;
				}
			}
		}
	}
}

@media screen and (min-width: 992px) {

	.home-page {

		.home-page-hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_home_hero.png');

			.content-container {
				text-align: left;
				padding: 100px 60px 120px 60px;
				background: rgba(255, 255, 255, .5);

				.container {
					
				}

				h2 {
					text-align: left;
					max-width: 100%;
					font-size: 44px;
					max-width: 600px;
					margin: initial;
					margin-left: 0px;
				}

				p {
					margin: initial;
					text-align: left;
					max-width: 100%;
					margin-bottom: 75px;
					max-width: 600px;
					margin-left: 0px;
				}

				hr {
					margin: initial;
					margin-top: 35px;
					margin-bottom: 35px;
					margin-left: 0px;
					margin-right: auto;
				}
			}
		}

		.patient-care {
			background: #f1f5f7;
			padding-top: 50px;

			.col-xs-12 {
				background: #f1f5f7;
				padding: 0px;
			}

			.content {
				background: #FFFFFF;
				padding-top: 0px;
				background-image: none;
				text-align: left;

				.container-fluid {
					max-width: 750px;
					padding-right: 20px;
					padding-left: 40px;
					margin-left: 0px;
					margin-right: 0px;
					background-image: url(/Portals/_default/Skins/integriTheme/Images/bjc-home-care-inner-page-content-top-swoosh.png);
					background-repeat: no-repeat;
					background-position-y: -25px;
					background-position-x: 105px;
					padding-top: 30px;
					background-size: 90%;
				}
			}

			.patient-care-services {
				padding-right: 40px;
				max-width: 100%;

				ul {

					li:hover {
						background: #eff6f9;

						a {
							color: #006A91;
						}
					}
				}
			}
		}

		.ctas-container {

			.container {
				max-width: 1350px;
				width: auto;
			}

			.cta {
				max-width: 385px;
				padding: 0px 20px;
			}
		} /* cta-container */

		.patient-care {

			.content {
				img {
				}
			}
		}


		.what-we-do {
			background: #f1f5f7;

			.content { 
				text-align: left;
				padding-right: 20px;
				padding-left: 20px;
				background-image: none;
				background: #FFFFFF;

				.container-fluid {
					padding-left: 20px;
					padding-right: 40px;
					margin-left: 0px;
					margin-right: 0px;
					background-image: url(/Portals/_default/Skins/integriTheme/Images/bjc-home-care-inner-page-content-top-swoosh.png);
					background-repeat: no-repeat;
					background-position-y: -36px;
					background-position-x: 45px;
					padding-top: 30px;
					background-size: 90%;

					a.rounded-button {
						max-width: 185px;
						margin: 0 auto;
						display: block;
					}
				}

				.video-container {
					display: none !important;
				}
			}

			.image-right {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/bjc-home-care-what-we-do.png');
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}

			.col-md-6 {
				padding: 0px;
			}
		}

		.testimonial {
			background: #f1f5f7;
			padding: 100px 0px 115px 0px;

			p {
				max-width: 870px;
				margin: 0 auto;
			}
		}


	} /* home-page */

} /* min-width 992px */

@media screen and (min-width: 1200px) {
	.home-page {

		.home-page-hero {
			background-position: center;

			.content-container {


				h2 {
					text-align: left;
					max-width: 100%;
					font-size: 47px;
					max-width: 600px;
					margin: initial;
				}
			}
		}

		.ctas-container {

			.container {
				max-width: 1520px;
				width: auto;
			}

			.cta {
				padding: 0px 30px;
			}
		} /* cta-container */

		.patient-care {

			.content {
				box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
				margin-bottom: 45px;

				.container-fluid {
					margin-top: 80px;
					padding-bottom: 40px;
				}
			}

			.patient-care-services {
				ul {
					border: 0px;
					border-bottom: 1px solid $baby-blue;
					border-radius: 0px;

					li.header-row {
						display: none;
					}

					li {
						width: 40%;
						float: left;
					}

					li:nth-child(odd) {
						border-left: 1px solid $baby-blue;
						width: 60%;
					}

					li.stroke {
						border-bottom: 0px;
					}
				}
			}
		} /* patient-care */

		.what-we-do {

			.content {
				box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
				margin-bottom: 45px;
				margin-top: 45px;
				padding-top: 50px;
				padding-bottom: 100px;

				.container-fluid {
					max-width: 700px;
					margin-left: auto;

					h3 {
						font-size: 30px;
					    line-height: 40px;
					    margin-bottom: 60px;
					}
				}
			}

			.image-right {
				margin-left: -10px;
			}
		}
	} /* home-page */

} /* home 1200up */

/** Sub Page */

#dnn_SidebarContentPane {
	margin-top: 30px;
}

.sub-page {
	.upper-decker {
		margin-bottom: 80px;
	}
}
@media screen and (max-width: 1199px) {
	.sub-page {

		.upper-decker p {
			text-align: left !important;
		}
	}
}

@media screen and (min-width: 1200px) {
	.sub-page {
		.contentPane {
			padding-left: 40px;
			padding-top: 25px;
		}

		.full-width.contentPane {
			padding-left: 0px;
		}

		.upper-decker {
			margin-bottom: 0px;
			background: #FFFFFF;
			top: -100px;
			position: relative;
			z-index: 9999 !important;
			-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
			-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
			box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
			background: url('/Portals/_default/Skins/integriTheme/Images/bjc-home-care-inner-page-content-top-swoosh.png');
			background-color: #FFFFFF;
			background-size: cover;
			background-position: center;
			background-position-y: 175px;
			background-position-x: 0px;
			background-size: 75%;
			background-repeat: no-repeat;
			padding: 70px 150px 120px 150px;
		}
	}
}

.hero-container {
	position: relative;
	margin-bottom: 20px;

	.color-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 2;
		display: none;
	}

	.hero {
		background-position: center;
		background-size: cover;
		z-index: 1;

		h1 {
			color: #FFFFFF;
			font-size: 38px;
			text-align: center;
			padding: 150px 0px;
			z-index: 3;
			margin-top: 0px;
			margin-bottom: 0px;
			position: relative;
		}
	}

	.medical-equipment.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_medical_equipment_hero.png');
	}

	.contact-us.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_contact_us_hero.png');
	}

	.about.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_about_hero.png');
	}

	.patient-care.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_patient _care_hero.png');
	}

	.home-health.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_home health_hero.png');
	}

	.iv-infusion.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_iv_infusion_services_hero.png');
	}

	.hospice.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_hospice_hero.png');
	}

	.pediatrics.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_pediatric_hero.png');
	}

	.resources.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_resources_hero.png');
	}

	.careers.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_careers_hero.png');
	}

	.for-physicians.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/mobile/BJC_homecare_mobile_for_physician_hero.png');
	}

} /* hero-container */

@media screen and (min-width: 992px) {

	.hero-container {

		.medical-equipment.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_medical_equipment_hero.png');
		}

		.contact-us.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_contact_us_hero.png');
		}

		.about.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_about_hero.png');
		}

		.patient-care.hero {
			background-position: top;
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_patient_care_hero.png');
		}

		.home-health.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_home_health_hero.png');
		}

		.iv-infusion.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_iv_infusion_servivces_hero.png');
		}

		.hospice.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_hospice_hero.png');
		}

		.pediatrics.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_pediatric_hero.png');
		}

		.resources.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_resources_hero.png');
		}

		.careers.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_careers_hero.png');
		}

		.for-physicians.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/hero/desktop/BJC_homecare_desktop_for_physician_hero.png');
		}
	}
}

.sidebar-child-pages {

	h4 {
		font-family: $lato;
		font-weight: 300;
		font-size: 17px;
		text-transform: uppercase;
		color: $grey;
	}

	.page-list-container {
		border-top: 2px solid $blue;
		-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);
		-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);
		box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);
		padding: 10px;

		a {
			font-size: 15px;
			font-weight: 600;
			color: $baby-blue;
			text-decoration: none !important;
		}

		a:hover {
			color: $blue;
		}

		a.active {
			color: $blue;
		}

	} /* page-list-container */
} /* child-page-list */


/* CONTACT US PAGE STYLES */

/* CONTACT FORM */
.section-title.underlined {
	text-decoration: none !important;
	border-bottom: 0px !important;
	text-transform: uppercase;
	color: $baby-blue;
	font-size: 13px;
}

.form-control {
	padding-left: 15px !important;
	color: $black !important;
	margin-bottom: 10px !important;

}


.form-control::placeholder {
	color: #CCC !important;
}

.form-control:focus,
.form-control:active,
.form-control:hover {
	-webkit-box-shadow: inset 0 -1px 0 $blue !important;
	box-shadow: inset 0 -1px 0 $blue !important;
}

#dnn423CommentsandQuestions{
	padding: 20px;
}

#dnn423CommentsandQuestions::placeholder {
	opacity: 0;
}

.checkbox-list {
	margin-top: -20px;

	.control-label {
		color: #707070;
		font-size: 10px;
		font-style: italic;
		font-weight: 300;
	}

	input[type=checkbox]:after {
		border: 1px solid #707070 !important;
		border-radius: 0px !important;
	}

	input[type=checkbox]:checked:after {
		background-color: $baby-blue !important;
		border-color: $baby-blue !important;
	}

	label {

		span {
			padding-left: 10px !important;
			font-size: 16px;
			line-height: 18px;
		}
		
	}

} /* checkbox-list */

.btn.submit {
	color: #FFFFFF !important;
	background: $blue!important;
	display: block !important;
	margin: 0 auto !important;
	max-width: 275px !important;
	text-align: center !important;
	border-radius: 32px !important;
	font-size: 14px !important;
	padding: 10px 65px !important;
	margin-top: 40px !important;
}



/* CONTACT US PAGE LOCATIONS */

.location-box {
	-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
	-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
	box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
	margin-bottom: 35px;
	border-top: 2px solid #65AAC7;
	padding: 25px 20px;
	min-height: 140px;
	max-width: 320px;
	margin: 0 auto;
	margin-bottom: 40px;

	h4 {
		color: $blue;
		font-weight: 600;
		font-size: 20px;
		margin-top: 0px;
	}

	p {
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 767px) {
	#dnn_BottomContentPane h3 {
		text-align: center;
	}

	.location-box {
		max-width: 100%;
	}

}


@media screen and (min-width: 768px) {
	.location-box {
		width: calc(50% - 55px);
		margin-right: 15px;
		max-width: initial;
		float: left;
	}
}

@media screen and (min-width: 992px) {

	.location-box {
		width: calc(25% - 70px);
		float: left;
		margin-right: 30px;
		padding: 25px 20px;
	}
}


/* Expandable text/html */
.ModICGModulesExpandableTextHtmlC {
	margin-top: -30px;
	margin-bottom: 25px;

	.ICG_ETH_Expanders {
		background: $baby-blue;
		overflow: hidden;
		padding: 20px 0px;
		border-radius: 5px;

		.ICG_ETH_Title {

			a.SubHeadTitle:first-of-type {
				position: absolute;
				left: -10px;
				top: -100px;
				display: none;
			}

			a.SubHeadTitle:nth-of-type(2) {
				padding-right: 65px;
			}

			a.SubHeadTitle:nth-of-type(2),
			a.SubHeadTitle:nth-of-type(2) p {
				color: #FFFFFF !important;
				font-weight: 600;
				font-size: 22px;
				text-decoration: none !important;
				padding-left: 20px;
				display: block;
				position: relative;
				margin-bottom: 0px;
			}

			.SubHeadTitle:hover {
				color: #FFFFFF;
				text-decoration: none;
			}

			.SubHeadTitle::after {
				content: "\f055";
				font-family: $fontawesome;
				position: absolute;
				top: 0px;
				right: 15px;
				color: #FFFFFF;
			}

			.SubHeadTitle.expanded:after {
				content: "\f056";
				font-family: $fontawesome;
				top: 2px;
				right: 15px;
				color: #FFFFFF;
			}
		}
	} /*  ICG_ETH_Expanders  */

	.Content {
		-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);
	    -moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);
	    box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.08);

	    > div {
	    	padding: 28px 25px;

	    	* {
	    		font-size: 15px;
	    		color: #09394B;
	    	}

	    	ul {
	    		margin-left: 15px;

	    		li {
	    			margin-bottom: 10px;
	    		}

	    		ul {

	    			li {
	    				list-style-type: square;
	    			}
	    		}
	    	}
	    }
	} /* content */

} /* ModICGModulesExpandableTextHtmlC */

@media screen and (min-width: 992px) {
	.hero-container {

		.hero {
			h1 {
				padding: 165px 0px;
				font-size: 55px;
			}
		}
	}

	.ModICGModulesExpandableTextHtmlC .Content > div * {
		font-size: 16px;
	}
}

/* equipment */

.equipment {

	.edn_420_article_list_wrapper {
		width: 100% !important;
	}

	#equipment-item {
		width: 100% !important;
		-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		border-radius: 0px !important;
		margin-bottom: 25px !important;

		.admin_action.edit {
		  background-color: $baby-blue;
		  padding: 10px 0px;
		  color: #FFFFFF;
		  display: block;
		  margin-right: 0px;
		}

		.admin_action.edit:hover {
			text-decoration: none;
		}

		.article_image {
			border-radius: 0px !important;
		}


		img {
			width: 100%;
			height: auto;
			border-radius: 0px !important;
		}

		.post-content {
			padding: 35px 20px;

			h3 {
				margin-top: 0px;
				font-weight: 600;
				color: $baby-blue;
				font-weight: 600;

				a {
					color: $baby-blue;
					font-weight: 600;
				}

				a:hover {
					text-decoration: none !important;
				}
			}

			.summary {

				p {
					margin-bottom: 0px;
					font-size: 14px;
				}
			}
		}
	}
} /* equipment */


@media screen and (min-width: 768px) {
	.equipment {

		#equipment-item {
			width: 47% !important;
		}
	} /* equipment */
}

@media screen and (min-width: 992px) {
	.equipment {

		#equipment-item {
			width: calc(33% - 20px) !important;
		}
	} /* equipment */
}


/* select a category dropdown */

.select-a-category {
	margin-left: 10px;
	margin-bottom: 40px;
	position: relative;

	.menu-container {
		-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
		max-width: 355px;
		background: #FFFFFF;
		border-radius: 20px;
    	position: relative;
    	cursor: pointer;
    	margin-top: 5px;
    	padding: 6px 20px;
	}

	.filter-by {
		font-size: 13px;
		text-transform: uppercase;
	}

	.click-to-open {

    	span {
    		font-size: 16px;
    	}

    	i {
    		position: absolute;
    		right: 20px;
    		top: 5px;
    		color: $grey;
    	}
	}

	// .click-to-open::after {
	// 	content: "\f0dd";
	// 	font-family: $fontawesome;
	// 	position: absolute;
	// 	right: 0px;
	// 	top: 0px;
	// 	color: $grey;
	// }

	.edn_category_menu_wrapper {
		list-style-type: none;
		display: none;

		li {
			list-style-type: none;

			a {
				color: $baby-blue;
				font-size: 16px;
			}

			a:hover {

			}
		}
	}

	.edn_category_menu_wrapper.open {
		max-width: 355px;
		display: block;
		background: #FFFFFF;
		border-radius: 20px;
		position: absolute;
		top: 40px;
		left: 0px;
		z-index: 9999;
		width: 100%;
		-webkit-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
    	-moz-box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
    	box-shadow: 0px 3px 12px rgba(101, 170, 199, 0.18);
    	margin-left: 0px;
    	padding: 6px 20px;
	}

} /* select-a-category */

@media screen and (max-width: 991px) {
	.select-a-category {

		.menu-container{
			max-width: 100%;
		}

		.edn_category_menu_wrapper.open {
			max-width: 652px;
		}
	}
}


/** pagination **/

.article_pager {
	margin-top: 40px !important;

	.page {
		border-radius: 30px !important;
		border: 1px solid $baby-blue !important;
		background: #FFFFFF !important;
		font-size: 18px !important;
		text-align: center !important;
		box-shadow: none !important;
		color: $baby-blue !important;
		padding: 12px !important;
		height: 12px !important;
		width: 14px !important;
		margin-right: 10px !important;
		text-decoration: none !important;
	}

	.active.page {
		background: $baby-blue !important;
		color: #FFFFFF !important;
	}

} /* article_pager */



footer.questions {
	background: #FFFFFF;
	background-image: url('/Portals/_default/Skins/integriTheme/Images/bjc-home-care-footer-swoosh-mobile.png');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 80px 0px;
	-webkit-box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
	-moz-box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
	box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
	margin-top: 50px;


	h3 {
		color: $blue;
		font-size: 30px;
		font-weight: 600;
		max-width: 250px;
		margin: auto;
		margin-bottom: 30px;
	}

	ul {
		margin-left: 0px;
		margin-bottom: 0px;
		padding: 0px;

		li {
			list-style-type: none;
			margin-bottom: 18px;

			a {
				color: $grey;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 18px;

				i {
					color: $blue;
					margin-right: 5px;
				}
			}

			a:hover {
				text-decoration: none !important;
				color: $baby-blue;

				i {
					color: $baby-blue;
				}
			}
		}

		li:last-of-type {
			margin-bottom: 0px;
		}
	}
} /* footer.questions */

@media screen and (min-width: 600px) {
	footer.questions {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/bjc-home-care-footer-swoosh.png');
	}
} /* min-width: 600px */

@media screen and (min-width: 992px) { 
	footer.questions {
		margin-top: 80px;
		padding: 75px 0px;
		background-size: cover;
		background-position: center;

		h3 {
			font-size: 32px;
			max-width: 100%;
		}

		ul {

			li {
				display: inline-block;
				margin-bottom: 0px;
				margin-right: 25px;
			}

			li:last-of-type {
				margin-right: 0px;
			}
		}

	} /* footer.questions */

} /* min-width: 992px */

footer {
	background: #18779B;
	padding: 60px 0px;
	text-align: center;

	* {
		color: #FFFFFF;
	}

	hr {
		display: none;
	}

	.bjc-logo {
		margin-bottom: 25px;
	}

	.address-contact {
		p {
			margin-bottom: 2px;
			color: #FFFFFF !important;

			a {
				color: #FFFFFF;
			}
		}
	}

	.copyright {
		margin-top: 30px;
		margin-bottom: 40px;
		float: none;
	}

	.footer-menu {
		ul {
			margin-left: 0px;
			padding-left: 0px;
			margin-bottom: 0px;

			li {
				list-style-type: none !important;
				margin-bottom: 8px;

				a {
					color: #FFFFFF;
				}

				a:hover {
					text-decoration: none;
					color: #FFFFFF;
				}
			}
		} /* ul */
	} /* footer-menu */

} /* footer */

@media screen and (min-width: 992px) {

	footer {

		.footer-menu {
			ul {
				li {
					display: inline-block;
					padding-right: 20px;
				}
			}
		}
	} /* footer */

} /* 992px up */

#google-map iframe {
	pointer-events: none;
}

#google-map.active iframe {
	pointer-events: inherit;
}

/** Slideout.js styles **/
body {
  width: 100%;
  height: 100%;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 280px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
  overflow-x: hidden;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}


.donation-form {

	* {
		font-size: 16px !important;
	}

	.radio-as-buttons  {
		background: transparent !important;
		box-shadow: none !important;
		margin-right: 25px;
	}

	.radio-as-buttons:hover,
	.radio-as-buttons:focus,
	.radio-as-buttons:active {
		background: transparent;
	}

	.radio-btn-text {
		display: none !important;
	}

	.radio-boxes-labels span {
		font-size: 16px;
		text-transform: none !important;
	}

	.radio-boxes-labels span:hover,
	.radio-boxes-labels span:focus,
	.radio-boxes-labels span:active,
	.radio.radio-as-buttons label input[type="radio"]:checked+span.btn {
		background-color: $blue !important;
		color: #FFFFFF !important;
	}

	.section-title.underlined {
		margin-bottom: 3px;
		font-size: 13px !important;
		margin-top: 0px;
	}

	input[type=radio] span {
		font-size: 16px;
	}

	h4 {
		font-size: 20px !important;
		margin-bottom: 0px;
		margin-top: 40px;
	}

	.bstrap3-material input[type=checkbox]:checked:after, .bstrap3-material .checkbox input[type=checkbox]:checked:after, .bstrap3-material .checkbox-inline input[type=checkbox]:checked:after {
		background: $blue !important;
		border-color: $blue !important;
	}

	.bstrap3-material input[type=radio]:checked:after, .bstrap3-material .radio input[type=radio]:checked:after, .bstrap3-material .radio-inline input[type=radio]:checked:after {
		border-color: $blue !important;
	}

	.bstrap3-material input[type=radio]:before, .bstrap3-material .radio input[type=radio]:before, .bstrap3-material .radio-inline input[type=radio]:before {
		background: $blue !important;
	}

} /* donation-form */

 /* SEARCH RESULTS */
.dnnSearchResultItem-Title {
	display: none !important;
}

.dnnSearchBoxPanel .dnnSearchBox {
	height:40px;
	margin-right: 10px;
}

.dnnSearchResultAdvancedTip {
	display: none;
}

.dnnSearchBox > .dnnSearchBox_advanced > .dnnSearchBox_advanced_label {
	background-position-x: 99% !important;
    background-position-y: 75% !important;
}



.join-our-team-popup {
	position: fixed;
	bottom: 0px;
	right: 10px;
	max-width: 333px;
	min-width:333px;
	z-index: 99;

	.pop-this-up {
		display: none;
	}

	.popup-button {
		cursor: pointer;
		display: block;
		text-align: right;
		position: absolute;
		right: 0px;
		top: -50px;

		i {
			font-size: 15px;
		    color: #FFFFFF;
		    border-radius: 39px;
		    text-align: center;
		    background: #65AAC7;
		    padding: 14px;
		    width: 44px;
		}
	}

	.popup-content {

		// .header {
		// 	background: #65AAC7;
		// 	text-align: center;
		// 	border-top-left-radius: 27px;
		// 	border-top-right-radius: 27px;
		// 	padding: 35px;
		// 	background-image: url(/Portals/_default/Skins/integriTheme/images/header-streak.png);
		// 	background-size: 120%;
		// 	background-position-y: -25px;
		// 	background-position-x: -20px;

		// 	img {
		// 		width: 140px;
		// 		height: auto;
		// 		margin: 0 auto;
		// 	}
		// }

		.job-search {
			padding: 40px 25px 40px 25px;
			background: #FFFFFF;
			box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);
			-webkit-box-shadow: 0px 3px 19px rgba(101, 170, 199, 0.2);

			h3 {
				text-align: center;
				margin-bottom: 30px;
				margin-top: 15px;
			}

			select {
				display: block;
				font-size: 16px;
				color: #707070;
				line-height: 1.3;
				padding: 5px;
				width: 100%;
				max-width: 100%;
				box-sizing: border-box;
				margin: 0;
				border-top: 0px;
				border-right: 0px;
				border-left: 0px;
				border-bottom: 1px solid #E8E8E8;
				border-radius: 0px;
				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;
				background-color: #fff;
				position: relative;
				height: 40px;
				background-image: url('/Portals/0/Images/Home/caret-down-solid.png');
				background-size: 12px;
			    background-position-x: 98%;
			    background-position-y: 17px;
			    background-repeat: no-repeat;
			}

			select::-ms-expand {
				display: none;
			}
			select:hover {
				border-color: $blue;
			}
			select:focus {
				border-color: $blue;
				outline: none;
			}

			select:active {
				border-color: $blue;
			}

			select option {
				font-weight:normal;
				font-size: 14px;
				margin-bottom: 5px;
				max-width: 320px;
			}

			input {
				height: 40px;
				border-top: 0px;
				border-bottom: 1px solid #D2DEEB;
				border-left: 0px;
				border-right: 0px;
				width: 100%;
				display: block;
				font-size: 16px;
				background: #FFFFFF;
			}

			input::placeholder {
				color: #707070;
				font-size: 16px;
			}

			input:hover {
				border-bottom: 1px solid $blue;
			}

			input:focus,
			input:active {
				border-bottom: 1px solid $blue;
				border-left: 0px;
				border-right: 0px;
				border-top: 0px;
				outline: none;
			}

			.keyword-search {
				margin-bottom: 40px;
			}

			.rounded-button.learn-more {
				background: $blue;
				display: block;
				color: #FFFFFF;
				text-align: center;
				margin-top: 40px;
				cursor: pointer;
			}

		}
	} /* popup-content */

	.click-to-open {
		background: #65AAC7;
		color: #FFFFFF;
		cursor: pointer;
		text-align: center;
		border-top-left-radius: 27px;
		border-top-right-radius: 27px;

		h3 {
			margin: 0px;
			font-size: 18px;
			padding: 15px 0px;
			color: #FFFFFF;
		}	
	} /* click-to-open */

	.click-to-open.active {

		i::before {
			content: "\f107";
			font-family: $fontawesome;
		}
	}
} /* join-our-team-popup */

.join-our-team-popup.active {

	.popup-button {
		display: block;

		i::before {
			content: '\f00d';
			font-family: $fontawesome;
		}
	}

	.popup-content {
		display: block;
	}
} /* join-our-team-popup.active */